.newsletter {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#a8ffd9),
    to(hsla(0, 0%, 100%, 0.7))
  );
}


.navlink {
  color: #ffffff;

}

.subscribe {
  display: inline-flex;
  white-space: nowrap;
  -webkit-box-align: center;
  align-items: center;
  pointer-events: auto;
  text-align: center;
  padding: 20px 65px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 30px;
  border-radius: 40px;
  cursor: pointer;
}







